.project-card {
  margin: 0 0 1em 0;
  border: solid 1px #bdc3c7;
  background: #f5f6fa;
  border-radius: 5px;
  padding: 0.5em 1em;
  -webkit-transition: all 0.5s linear;
  -moz-transition: all 0.5s linear;
  -o-transition: all 0.5s linear;
  transition: all 0.5s linear;
  display: block;
  height: auto;
}

.project-card:hover {
  -webkit-transition: all 0.5s linear;
  -moz-transition: all 0.5s linear;
  -o-transition: all 0.5s linear;
  transition: all 0.5s linear;
}

.technologies {
  text-align: center;
}

.technology {
  color: #21ab85;
  font-weight: bold;
  white-space: nowrap;
  display: inline-block;
  padding: 0.3em 0.5em;
  /*
  padding: 0.3em 0.5em;
  border-radius: 5px;
  background: #21ab85;
  color: #ffffff;
  margin: 0.2em;
  cursor: pointer;
  */
}
/*

.technology:hover {
    background: #3498db;
}
*/

.project-title {
  text-transform: lowercase;
  font-weight: 700;
  font-size: 2rem;
  padding: 0 1em;

}

.worked-on {
}

.project-description {
  overflow : hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  text-align: center;
}

@-moz-document url-prefix() {
  .project-description {
    max-height: 200px;
    overflow-y: hidden;
  }
}

.side {
  color: #21ab85;
  font-weight: bold;
  white-space: nowrap;
  display: inline-block;
  padding: 0.3em 0.5em;
}

/* flip */

/* entire container, keeps perspective */
.flip-container {
  perspective: 1000px;
}
/* flip the pane when hovered */
.flip-container:hover .flipper, .flip-container.hover .flipper {
  transform: rotateY(180deg);
}

.front, .back {
  width: 100%;
  height: 400px;
}

.front {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-direction: column;
  margin: 0 0 1em 0;
  border: solid 1px #bdc3c7;
  background: #f5f6fa;
  border-radius: 5px;
  padding: 0.5em 1em;
}

.back {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.flip-container {
  display: inline-block !important;
  float: left;
  width: 100%;
  height: 400px;
  margin: 1em 2% 1em 2%;
}

/* flip speed goes here */
.flipper {
  transition: 0.6s;
  transform-style: preserve-3d;
  position: relative;
}

/* hide back of pane during swap */
.front, .back {
  backface-visibility: hidden;
  position: absolute;
  top: 0;
  left: 0;
}

/* front pane, placed above back */
.front {
  z-index: 2;
  /* for firefox 31 */
  transform: rotateY(0deg);
}

/* back, initially hidden pane */
.back {
  transform: rotateY(180deg);
}


@media only screen and (min-width: 577px) {
  .flip-container {
    width: 95% !important;
  }
}

@media only screen and (min-width: 870px) {
  .flip-container {
    width: 45% !important;
  }
}

