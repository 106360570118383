@import url("https://fonts.googleapis.com/css2?family=Niramit:wght@200;400;500;700&display=swap");

body {
  background-color: #ecf0f1;
  overflow: hidden;
}

* {
  font-family: "Niramit", sans-serif;
  box-sizing: border-box;
}

a {
  text-decoration: none;
  color: #0099ff;
}

button {
  background-color: #343a40;
  border-color: #343a40;
  color: #ffffff;
  cursor: pointer;
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  &:hover {
    background-color: #000000;
  }
}

.btn-block {
  width: 100%;
}

input, textarea {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

textarea {
  min-height: 150px;
}
